import React from "react"
import Form from "./shared/Form"
import defaultsData from "../utils/formStructure"

const Contact = () => {
    return (
        <div className="main-form">
            <div className="container contato-title">
                <h2>Contato</h2>
            </div>
            <section className="contato-custom">
                <Form type="Contato" structure={defaultsData.Contato} />
            </section>
        </div>
    )
}

export default Contact;